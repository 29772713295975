<template>
  <div>
    <nav
      class="bg-gray-50 text-center grid grid-cols-5 mx-auto lg:w-4/5 2xl:w-4/5"
    >
      <template v-for="men in menu" :key="men.id">
        <input
          :id="men.id"
          type="radio"
          :checked="men.category === 'all'"
          name="filterGamesVR"
          class="hidden"
        />
        <label
          :for="men.id"
          class="h-100 p-2 w-100 font-semibold text-gray-400 cursor-pointer"
          @click="onChangeFiltersThreads(men.category, men.text)"
          >{{ men.text }}
        </label>
      </template>
    </nav>
  </div>
</template>

<script setup>
import { menu } from "@/components/gamesVR/utils";

const emit = defineEmits(["changeFilter"]);

function onChangeFiltersThreads(category, text) {
  emit("changeFilter", { category, text });
}
</script>

<style lang="scss" scoped>
input:checked + label {
  background-color: #edfe86;
  width: 100%;
  color: #1d2e48;
}

nav router-link-active,
nav .router-link-exact-active {
  background-color: #edfe86;
  width: 100%;
  color: #1d2e48;
  border: 1px solid grey;
}
</style>
