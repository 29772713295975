const menu = [
  {
    text: "All",
    id: "1",
    category: "all",
  },
  {
    text: "Star Catcher",
    id: "2",
    category: "starcatcher",
  },
  {
    text: "Sun Blazer",
    id: "3",
    category: "sunblazer",
  },
  {
    text: "Moon Shadow",
    id: "4",
    category: "moonshadow",
  },
  {
    text: "Coherence",
    id: "5",
    category: "coherence",
  },
];

const gamesVR = [
  {
    number: "1",
    title: "Track",
    text: "The goal of this game is to hit all of the balls coming towards you with your paddles to not allow them to pass you.",
    category: "starcatcher",
  },
  {
    number: "2",
    title: "Decide",
    text: "The goal of this game is to hit only the designated color of balls assigned to you with your paddles (does not matter which paddle).",
    category: "starcatcher",
  },
  {
    number: "3",
    title: "Predict",
    text: "The goal of this game is to be able to predict the path of the ball after it has been shot towards you to be able to hit all balls with either paddle (hand).",
    category: "starcatcher",
  },
  {
    number: "4",
    title: "Switch",
    text: "The goal of this game is to hit the designated color of ball with the correct paddle each time the assigned colored balls are shot towards you; peridoically, your paddle assignments will change but you will only see colors assigned to your paddles.",
    category: "starcatcher",
  },
  {
    number: "5",
    title: "Listen",
    text: "The goal of this game is to hit only the designated colors of the balls with either paddle each time the assigned colored balls are shot towards you; peridoically, your colors will change.",
    category: "starcatcher",
  },
  {
    number: "6",
    title: "Order",
    text: "The goal of this game is to hit the colored balls in the order they appeared at the top of the screen, moving left to right.",
    category: "starcatcher",
  },
  {
    number: "7",
    title: "Decide and Switch",
    text: "The goal of this game is to hit the designated color of ball with the correct paddle each time the assigned colored balls are shot towards you; peridoically, you will see distractor balls (colors not assigned to you).",
    category: "starcatcher",
  },
  {
    number: "8",
    title: "Switch and Decide",
    text: "The goal of this game is to hit the designated color of ball with either paddle as the balls are shot towards you; peridoically, your color assignments will change and you will see distractor balls (colors not assigned to you).",
    category: "starcatcher",
  },
  {
    number: "9",
    title: "Listen and Switch",
    text: "The goal of this game is to hit only the designated colors of the balls assigned to you as the balls are shot towards you; peridoically, your color assignments will change.",
    category: "starcatcher",
  },
  {
    number: "10",
    title: "Predict and Decide",
    text: "The goal of this game is to be able to predict the path of the ball after it has been shot towards you to be able to hit the assigned colors with the appropriate paddle.",
    category: "starcatcher",
  },
  {
    number: "11",
    title: "Predict and Switch",
    text: "The goal of this game is to be able to predict the path of the ball after it has been shot towards you to be able to hit the assigned colors with the appropriate paddle; paddle colors will switch throughout the game.",
    category: "starcatcher",
  },
  {
    number: "12",
    title: "Predict and Order",
    text: "The goal of this game is to be able to predict the path of the ball after it has been shot towards you to be able to hit the assigned colors with the appropriate paddle; colors need to be hit in the order displayed from left to right.",
    category: "starcatcher",
  },
  {
    number: "13",
    title: "Order Decide and Switch",
    text: "The goal of this game is hit the balls in the correct color order in sequence using the correct assigned paddle.",
    category: "starcatcher",
  },
  {
    number: "14",
    title: "Listen and Order",
    text: "The goal of this game is hit the balls in the correct color order in sequence based on the audio guidance.",
    category: "starcatcher",
  },
  {
    number: "15",
    title: "AIM",
    text: "The goal of this game is to shoot all of the green targets that appear as quickly as possible while not shooting the trap targets.",
    category: "sunblazer",
  },
  {
    number: "16",
    title: "Divert",
    text: "The goal of this game is to hit all the standard targets (circles) as quickly as possible and avoid hitting the trap targets (triangles).",
    category: "sunblazer",
  },
  {
    number: "17",
    title: "Time",
    text: "The goal of this game is to shoot the green targets as quickly as possible; some targets are standard targets, some targets lose value the longer they aren't hit, and some targets change from standard targets to trap targets (you can shoot while they are green but should not shoot when they turn red).",
    category: "sunblazer",
  },
  {
    number: "18",
    title: "Guard",
    text: "The goal of this game is to shoot the targets as quick as you can; there are standard targets and diminishing targets, which  lose value the longer you don't hit them. Blockers will hover on the screen to try and prevent you from hitting the targets.",
    category: "sunblazer",
  },
  {
    number: "19",
    title: "Target",
    text: "The goal of this game is to shoot the targets as quick as you can. Turrets (floating orbs) will appear at random times and in various spots; you'll want to shoot them to destroy them or block their lasers with your shield.",
    category: "sunblazer",
  },
  {
    number: "20",
    title: "In Order",
    text: "The goal of this game is shoot the targets in numerical order.",
    category: "sunblazer",
  },
  {
    number: "21",
    title: "Identify",
    text: "The goal of this game is to shoot targets with the designated shapes listed across the top. The shapes will periodically change, and can be shot with either hand.",
    category: "sunblazer",
  },
  {
    number: "22",
    title: "Shape Voices",
    text: "The goal of this game is to shoot targets with the designated shapes that are verbalized to you. The shapes will periodically change, and can be shot with either hand.",
    category: "sunblazer",
  },
  {
    number: "23",
    title: "Task Switch",
    text: "The goal of this game is to shoot targets with the designated shapes listed across the top. The shapes will periodically change, and can be shot with either hand. Additionally, turrets (floating orbs) will appear at random times and in various spots; you'll want to shoot them to destroy them or block their lasers with your shield.",
    category: "sunblazer",
  },
  {
    number: "24",
    title: "Sequence",
    text: "The goal of this game is to memorize the order that the targets turn green and then shoot them in that order.",
    category: "sunblazer",
  },
  {
    number: "25",
    title: "Defend",
    text: "The goal of this game is to shoot the targets as quick as you can. Turrets (floating orbs) will appear at random times and in various spots; you'll want to shoot them to destroy them or block their lasers with your shield.",
    category: "sunblazer",
  },
  {
    number: "26",
    title: "Detect",
    text: "The goal of this game is to memorize which targets are standard targets and which ones are traps. A target will flash as either a standard target or a trap target, but then will revert to looking like standard targets. Only shoot the ones that flashed as standard targets.",
    category: "sunblazer",
  },
  {
    number: "27",
    title: "Spatial Memory",
    text: "The goal of this game is to memorize a displayed pattern with cues and repeat the patterns.",
    category: "moonshadow",
  },
  {
    number: "28",
    title: "Sequence",
    text: "The goal of this game is to memorize a displayed pattern (with cues) in a specific order and repeat the pattern.",
    category: "moonshadow",
  },
  {
    number: "29",
    title: "Reverse Sequence",
    text: "The goal of this game is to memorize a displayed pattern (with cues) in a specific order and repeat the pattern in reverse order.",
    category: "moonshadow",
  },
  {
    number: "30",
    title: "Hand Restriction",
    text: "The goal of this game is to memorize a displayed pattern (with cues) with specific colors and repeat the pattern using the correct hand assigned to each color.",
    category: "moonshadow",
  },
  {
    number: "31",
    title: "Shape and Color",
    text: "The goal of this game is to memorize a displayed pattern (with cues) with specific colors and shapes and repeat the pattern by hitting only the assigned shape or color.",
    category: "moonshadow",
  },
  {
    number: "32",
    title: "Rhythm",
    text: "The goal of this game is to memorize a displayed pattern (with cues) and muscial beat and repeat the pattern using the same beat (timing).",
    category: "moonshadow",
  },
  {
    number: "33",
    title: "Figure Decide",
    text: "The goal of this game is to memorize a displayed pattern (with cues) with multiple shapes and repeat the pattern by hitting only the assigned shape.",
    category: "moonshadow",
  },
  {
    number: "34",
    title: "Randomized Hand Restriction",
    text: "The goal of this game is to memorize a displayed pattern (with cues) with specific colors and repeat the pattern using the correct hand assigned to each color. Your hand assignments can change during each sequence.",
    category: "moonshadow",
  },
  {
    number: "35",
    title: "Number Order",
    text: "The goal of this game is to memorize a displayed pattern (with cues) and a set of numbers on them and repeat the pattern in either according to the order of numbers displayed.",
    category: "moonshadow",
  },
  {
    number: "36",
    title: "Rhythm Hand Restriction",
    text: "The goal of this game is to memorize a displayed pattern (with cues) with specific colors and also memorize the musical beat and repeat the pattern using the correct hand assigned to each color while staying on beat.",
    category: "moonshadow",
  },
  {
    number: "37",
    title: "Figure Decide Hand Restriction",
    text: "The goal of this game is to memorize a displayed pattern (with cues) with specific colors and shapes and repeat the pattern by hitting only the assigned shape or color (using the correct hand assigned to each color).",
    category: "moonshadow",
  },
  {
    number: "38",
    title: "Rhythm Sequence",
    text: "The goal of this game is to memorize a displayed pattern (with cues) in a specific order while also retaining the musical beat. You then will repeat that pattern in the same order and using the same beat (timing). ",
    category: "moonshadow",
  },
  {
    number: "39",
    title: "Coherencee",
    text: "The goal of this game is to work to improve rhythm, timing, and coordination.",
    category: "coherence",
  },
];

export { menu, gamesVR };
