<template>
  <section class="w-full max-h-screen">
    <div class="px-7 h-screen pt-7">
      <div class="w-100 flex flex-col justify-center">
        <h1 class="text-white text-lg font-semibold">VR Games</h1>
        <Menu @changeFilter="onChangeFiltersThreads" />
      </div>
      <CardInformation :targets="selectedCategory" :title="title" />
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import Menu from "@/components/gamesVR/Menu.vue";
import CardInformation from "@/components/gamesVR/CardInformation.vue";
import { gamesVR } from "@/components/gamesVR/utils";
const selectedCategory = ref(gamesVR);
const title = ref("");
function onChangeFiltersThreads({ category, text }) {
  if (category == "all") {
    selectedCategory.value = gamesVR;
    title.value = "";
    return;
  }
  selectedCategory.value = gamesVR.filter((game) => game.category === category);
  title.value = text;
}
</script>
